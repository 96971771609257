<template>
  <div class="content">
    <el-card class="box-card">
      <div
        slot="header"
        class="clearfix"
      >
        <span class="title">企业信息</span>
      </div>
      <div class="company-content">
        <el-descriptions>
          <el-descriptions-item label="企业名称">{{companyDetail.name}}</el-descriptions-item>
          <el-descriptions-item label="信用代码">{{companyDetail.uscc}}</el-descriptions-item>
          <el-descriptions-item label="海关代码">{{companyDetail.customsCode}}</el-descriptions-item>
          <el-descriptions-item label="所在地">{{companyDetail.provinceName}}{{companyDetail.cityName}}{{companyDetail.countyName}}</el-descriptions-item>
          <el-descriptions-item label="主要联系人">{{companyDetail.lmName}}</el-descriptions-item>
          <el-descriptions-item label="企业标签">{{companyDetail.type | companyType}}</el-descriptions-item>
          <el-descriptions-item v-if="companyDetail.userName" label="新联系人">{{companyDetail.userName}}</el-descriptions-item>
          <el-descriptions-item  v-if="companyDetail.phone" label="新联系方式">{{companyDetail.phone}}</el-descriptions-item>
          <!-- <el-descriptions-item>
            <el-tag
              style="margin-right:10px"
              size="small"
              v-for="(item, index) in companyDetail.tip"
              :key="index"
            >{{item}}</el-tag>
          </el-descriptions-item> -->
        </el-descriptions>

      </div>
    </el-card>
    <el-card
      class="box-card"
      style="margin-top:10px"
    >
      <div
        slot="header"
        class="clearfix"
      >
        <el-row
          type="flex"
          justify="space-between"
          :gutter="24"
        >
          <el-col :span="16">
            <span class="title">企业联系人</span>
          </el-col>
          <el-col
            :span="6"
            style="text-align:right"
          >
            <el-input
              placeholder="请输入姓名"
              prefix-icon="el-icon-search"
              v-model="name"
              clearable
              @keyup.enter.native="onSubmit"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <div class="report-content">
        <el-table
          :data="userData"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            label="姓名"
          >
          </el-table-column>
          <el-table-column
            prop="job"
            label="职务"
          >
          </el-table-column>
          <el-table-column
            prop="phoneNumber"
            label="联系方式"
          >
          </el-table-column>
          <el-table-column
            prop="uid"
            label="UID"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
          >
             <template slot-scope="scope">{{
                        scope.row.status | effective
                    }}</template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div>
          <Pagination
            v-bind:child-msg="userPageparm"
            @callFather="userCallFather"
          ></Pagination>
        </div>
      </div>
    </el-card>
    <el-card
      class="box-card"
      style="margin-top:10px"
    >
      <div
        slot="header"
        class="clearfix"
      >
        <el-row
          type="flex"
          justify="space-between"
          :gutter="24"
        >
          <el-col :span="16">
            <span class="title">问卷填报记录</span>
          </el-col>
          <el-col
            :span="6"
            style="text-align:right"
          >
            <el-input
              placeholder="请输入问卷名称"
              prefix-icon="el-icon-search"
              v-model="wjdo_title"
              clearable
              @keyup.enter.native="onSubmit1"
            >
            </el-input>
          </el-col>
        </el-row>

      </div>
      <div class="report-content">
        <el-table
          :data="reportData"
          border
          style="width: 100%"
        >
          <el-table-column
            fixed
            prop="date"
            label="编号"
            type="index"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="wjdo_title"
            label="问卷名称"
          >
          </el-table-column>
          <el-table-column
            prop="time"
            label="填报时间"
          >
          </el-table-column>
          <el-table-column
            prop="uid"
            label="UID"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="状态"
          >
            <template slot-scope="scope">{{
                        scope.row.status | effective
                    }}</template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div>
          <Pagination
            v-bind:child-msg="pageparm"
            @callFather="callFather"
          ></Pagination>
        </div>
      </div>
    </el-card>

  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { getCompanyTempDetailApi,getCompanyDetailApi, getCompanyServeyListApi,getCompanyUserListApi  } from '@/api/company'
export default {
   // 注册组件
  components: {
    Pagination
  },
  data(){
    return{
      userPageparm:{
        currentPage: 1,
        pageSize: 10,
        total: 20
      },
      name:'',//企业联系人搜索
      wjdo_title:'', // 问卷填报搜索
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 20
      },
      companyDetail:{},
      reportValue:5,
      sixReportValue:5,
      activityValue:'活跃',
      userData:[],
      reportData:[]
    }
  },
  created() {
    if(Number(this.$route.query.type)===1){
      this.getCompanyTempDetailFun()
    }else{
      this.getCompanyDetailFun()
    }
    
    this.getCompanyUserListFun()
    this.getCompanyServeyListFun()
  },
  methods:{
    // 获取小程序企业详情
    async getCompanyTempDetailFun(){
      var res = await getCompanyTempDetailApi(this.$route.query.id)
      this.companyDetail = res
    },
    // 获取企业详情
    async getCompanyDetailFun(){
      var params={
        uscc: this.$route.query.uscc
      }
      var res = await getCompanyDetailApi(params)
      this.companyDetail = res.data
    },
    // 企业联系人
    async getCompanyUserListFun(currentPage,pageSize){
      var start = currentPage ? currentPage * pageSize - pageSize : 0
      var params = {
          start: start,
          length: 10,
          draw: 1,
          orderStr:'time desc',
          uscc:this.$route.query.uscc,
          name:this.name
      }
      var res = await getCompanyUserListApi(params)
      this.userData = res.data
      this.userPageparm.total = res.recordsTotal
    },
    // 企业填报问卷列表
    async getCompanyServeyListFun(currentPage, pageSize){
      var start = currentPage ? currentPage * pageSize - pageSize : 0
      var params = {
          start: start,
          length: 10,
          draw: 1,
          orderStr:'time desc',
          uscc:this.$route.query.uscc,
          name:this.wjdo_title
      }
      var res = await getCompanyServeyListApi(params)
      this.reportData = res.data
      this.pageparm.total = res.recordsTotal
    },
     // 分页插件事件
    callFather(parm) {
      this.getCompanyServeyListFun(parm.currentPage, parm.pageSize)
    },
    // 企业联系人插件
    userCallFather(parm){
      this.getCompanyUserListFun(parm.currentPage, parm.pageSize)
    },
    // 企业联系人搜索
    onSubmit(){
      console.log('企业联系人', this.user);
      this.getCompanyUserListFun()
    },
    // 企业问卷搜索搜索
    onSubmit1(){
      console.log('企业联系人', this.name);
      this.getCompanyServeyListFun()
    },
  }
}
</script>
<style scoped>
.content {
  width: 1200px;
  margin: 20px auto 20px auto;
}
.card-box {
  height: 95px;
}
.report-title {
  color: #999999;
  font-size: 14px;
}
.report-value {
  font-weight: bold;
  font-size: 18px;
  color: #000;
  margin-top: 10px;
}
.report-list {
  margin: 15px;
  font-size: 14px;
  font-weight: bold;
}
.title {
  font-size: 15px;
  font-weight: bold;
}
</style>